import { render, staticRenderFns } from "./dictItem.vue?vue&type=template&id=101aa03f&scoped=true&"
import script from "./dictItem.vue?vue&type=script&lang=js&"
export * from "./dictItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "101aa03f",
  null
  
)

export default component.exports