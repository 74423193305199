<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryParams" :inline="true" label-width="68px">
      <el-col :md="6" :sm="12">
        <el-form-item label="字典编码" prop="dictCode">
          <el-input placeholder style="width:60%" v-model="queryParams.dictCode"></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="6" :sm="12">
        <el-form-item label="字典名称" prop="dictName">
          <el-input placeholder style="width:60%" v-model="queryParams.dictName"></el-input>
        </el-form-item>
      </el-col>
      <el-form-item>
        <el-button type="cyan" icon="el-icon-search" size="mini" @click="getDictListButton()">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetForm('queryParams')">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="addDcitButton()">新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" icon="el-icon-edit" size="mini" @click="editDcitButton()">修改</el-button>
      </el-col>
      <!-- <el-col :span="1.5">
        <el-button type="success" icon="el-icon-edit" size="mini" @click="recycleButton()">回收站</el-button>
      </el-col>-->
    </el-row>

    <el-table :data="dictList">
      <el-table-column label="序号" align="center" width="65">
        <template slot-scope="scope">
          <el-radio :label="scope.$index" v-model="radio" @change.native="getCurrentRow(scope.row)"></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="字典名称" align="center" prop="name" />
      <el-table-column label="字典编码" align="center" prop="code" />
      <el-table-column label="业务类型" align="center" prop="attribute" />
      <el-table-column label="属性" align="center" prop="attribute" />
      <el-table-column label="描述" align="center" prop="description" />
      <el-table-column label="归属应用" align="center" prop="productSource" />
      <el-table-column label="备注" align="center" prop="remark" />
      <el-table-column label="创建人" align="center" prop="createdTime" />
      <el-table-column label="更新时间" align="center" prop="modifiedTime" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="text"  icon="el-icon-setting" @click="dictItemButton(scope.row)">字典配置</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />

    <AddDict ref="addDictFrom"></AddDict>
    <dictItem ref="dictItemFrom"></dictItem>
  </div>
</template>
<script>
import AddDict from "./modules/addDict";
import { listDictApi } from "@/api/system/dict/dict.js";
import dictItem from "./modules/dictItem";
export default {
  components: {
    AddDict,
    dictItem
  },
  data() {
    return {
      queryParams: {
        dictCode: null,
        dictName: null,
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      total: 0,
      dictList: [],
      radio: "",
      id: null
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.getDictListButton();
    },
    "queryParams.pageSize"(val) {
      this.getDictListButton();
    },
  },
  created() {
    this.getDictListButton();
  },
  methods: {
    getDictListButton() {
      listDictApi(this.queryParams).then(res => {
        this.dictList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    dictItemButton(row) {
      this.$refs.dictItemFrom.show(row.id, row.code, row.productSource);
    },
    addDcitButton() {
      this.$refs.addDictFrom.title = "新增字典";
      this.$refs.addDictFrom.show(this.id, 1);
    },
    editDcitButton() {
      this.$refs.addDictFrom.title = "编辑字典";
      if (this.id == undefined) return this.$message.error("请选择一行");
      this.$refs.addDictFrom.show(this.id, 2);
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.id = row.id;
      this.templateSelection = row;
    },
    delectDictOpen(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          var a = { id: row.id, status: 2 };
          editStata(a).then(res => {
            console.log(res);
            this.getDictListButton();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    resetForm(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped>
</style>