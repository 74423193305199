<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="30%"
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <el-form :model="form" ref="form">
        <el-row>
          <el-col>
            <el-form-item label="属性" :label-width="formLabelWidth" prop="attribute">
              <el-radio v-model="form.attribute" :label="1">系统类型</el-radio>
              <el-radio v-model="form.attribute" :label="2">业务类型</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="form.attribute==2">
            <el-form-item label="应用归属" :label-width="formLabelWidth" prop="productSource">
              <el-select v-model="form.productSource" placeholder="请选择">
                <el-option
                  v-for="item in systemDataList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="字典编码" :label-width="formLabelWidth" prop="code">
              <el-input v-model="form.code" :disabled="editDisf"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="类型" :label-width="formLabelWidth" prop="type">
              <el-input v-model="form.type" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="样式回显" :label-width="formLabelWidth" prop="cssClass">
              <el-input v-model="form.cssClass" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="描述" :label-width="formLabelWidth" prop="description">
              <el-input v-model="form.description"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="备注" :label-width="formLabelWidth">
              <el-input v-model="form.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row></el-row>
        <el-row :gutter="32">
          <el-col :span="8">
            <el-form-item label="是否启用" :label-width="formLabelWidth" prop="status">
              <el-switch
                v-model="status"
                active-color="#ff4949"
                inactive-color="#13ce66"
                active-text="否"
                inactive-text="是"
                :active-value="2"
                :inactive-value="1"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="addDictButton()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  selectDictByIdApi,
  addDictApi,
  editDictApi
} from "@/api/system/dict/dict.js";
import { listproductApi } from "@/api/system/product/product.js";
export default {
  name: "addDict",
  data() {
    return {
      editDisf: false,
      dialogFormVisible: false,
      form: {
        status: 1,
        attribute: 1
      },
      title: null,
      status: 1,
      formLabelWidth: "120px",
      type: null,
      systemDataList: []
    };
  },
  created() {},
  methods: {
    show(id, type) {
      if (type === 2) {
        this.getDictById(id);
      }
      this.systemListButton();
      this.dialogFormVisible = true;
      this.type = type;
    },
    getDictById(id) {
      selectDictByIdApi(id).then(res => {
        this.form = res.result;
        this.editDisf = true;
      });
    },

    addDictButton() {
      this.form.status = this.status;
      if(this.form.attribute=='1'){
        this.form.productSource="xunCommon"
      }
      console.log("s", this.form);
      if (this.type === 1) {
        addDictApi(this.form).then(res => {
          this.cancelForm();
        });
      }
      if (this.type === 2) {
        editDictApi(this.form).then(res => {
          this.cancelForm();
        });
      }
    },
    systemListButton() {
      listproductApi(this.queryFrom).then(res => {
        this.systemDataList = res.result.records;
      });
    },
    cancelForm(formName) {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.dialogFormVisible = false;
      this.$refs["form"].resetFields();
      this.$parent.getDictListButton();
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          // this.loading = true;
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    }
  }
};
</script>
<style scoped>
</style>