<template>
  <div>
    <el-drawer
      title="字典列表"
      :visible.sync="table"
      direction="rtl"
      size="45%"
      :before-close="handleClose"
    >
      <el-form :model="queryParams" ref="queryParams" :inline="true" label-width="68px">
        <el-form-item label="发送时间" prop="sendTime">
          <el-date-picker
            clearable
            size="small"
            style="width: 200px"
            v-model="queryParams.sendTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择发送时间"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="cyan" icon="el-icon-search" size="mini" @click="getDictItem">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini">重置</el-button>
        </el-form-item>
      </el-form>
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="addDictItemButton()">新增</el-button>
        </el-col>
      </el-row>

      <el-table :data="dictItemFrom" width="160px">
        <el-table-column label="序号" align="center" width="100px">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column property="text" label="文本值" width="80"></el-table-column>
        <el-table-column property="value" label="对应的值" width="100"></el-table-column>
        <el-table-column property="showText" label="显示内容" width="80"></el-table-column>
        <el-table-column property="status" label="状态" width="50"></el-table-column>
        <el-table-column property="sortOrder" label="排序" width="50"></el-table-column>
        <el-table-column property="description" label="注解" width="100"></el-table-column>
        <el-table-column property="isDefault" label="是否默认" width="80"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="160px"
        >
          <template slot-scope="scope">
            <el-button
              type="Primary"
              icon="el-icon-delete"
              size="mini"
              @click="editDictItemButton(scope.row)"
            >编辑</el-button>
            <el-popconfirm
              placement="top"
              confirm-button-text="好的"
              cancel-button-type="不用了"
              icon="el-icon-info"
              icon-color="red"
              @onConfirm="delectDictItem(scope.row)"
              title="这是一段内容确定删除吗？"
            >
              <el-button slot="reference" size="mini" disabled="">删除</el-button>
            </el-popconfirm>
            <el-popconfirm
              placement="top"
              confirm-button-text="好的"
              cancel-button-type="不用了"
              icon="el-icon-info"
              icon-color="red"
              @onConfirm="editdefaultButton(scope.row)"
              title="这是一段内容确定删除吗？"
            >
              <el-button slot="reference" size="mini" disabled="">设置默认</el-button>
            </el-popconfirm>
            <el-popconfirm
              placement="top"
              confirm-button-text="好的"
              cancel-button-type="不用了"
              icon="el-icon-info"
              icon-color="red"
              @onConfirm="editStataButton(scope.row)"
              title="这是一段内容确定删除吗？"
            >
              <el-button slot="reference" size="mini" disabled="">启动</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
     
    </el-drawer>
        <AddDictItem ref="addDictItemFrom"></AddDictItem>
  </div>
</template>
<script>
import AddDictItem from "./addDictItme";
import { dictItemlist } from "@/api/system/dict/dict.js";
export default {
    components: {
    AddDictItem
  },
  data() {
    return {
      visible: true,
      dictItemFrom: [],
      statusOption: [],
      table: false,
      queryParams: {
        current: 1,
        pageSize: 10,
        total: 1
      },
      dictId: null,
      radio: null,
      dictCode: null,
      productSource: null,
      templateSelection: {},
      dictItemId:null
    };
  },
  created() {},
  methods: {
    show(id, code, productSource) {
      console.log('time',productSource)
      this.table = true;
      this.dictCode = code;
      this.getDictItem(code);
      this.dictId = id;
      this.productSource=productSource
    },
    getDictItem(code) {
      var dictItem = {
        dictCode: this.dictCode
      };

      dictItemlist(dictItem).then(res => {
        this.dictItemFrom = res.result.records;
      });
    },
    addDictItemButton() {
      this.$refs.addDictItemFrom.showFund(this.dictCode, this.productSource,1,null);
    },
    editDictItemButton() {
      if (this.dictItemId == undefined) return this.$message.error("请选择一行");
      this.$refs.addDictItemFrom.showFund(this.dictCode, this.productSource,2,this.dictItemId);
    },
    delectDictItem(row) {
      delectById({ id: row.id }).then(res => {
        this.getDictItem();
      });
    },
    editdefaultButton(row) {
      editdefault({ id: row.id, status: 1 }).then(res => {
        this.getDictItem();
      });
    },
    editStataButton(row) {
      var statuss = 1;
      row.status;
      if (row.status === 1) {
        statuss = 2;
      }
      if (row.status === 2) {
        statuss = 1;
      }
      editStata({ id: row.id, status: statuss }).then(res => {
        this.getDictItem();
      });
    },
    cancelForm() {
      this.table = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cashStateFrom(row, column) {
      return this.selectDictLabel(this.statusOption, row.status);
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      console.log('es',row)
      this.dictItemId = row.id;
      this.templateSelection = row;
    }
  }
};
</script>
<style scoped>
</style>